.nav-main{
    padding-bottom: 1rem;
}

.nav-link{
    position: relative;
}

.nav-size-toggle{
    background: transparent;
    border: none;
    align-self: flex-end;
    margin-top: auto;
    margin-right: .4rem;

    &:hover,&:focus{
        outline: none;
        background: transparent;
        opacity: .8;
        box-shadow: none;
    }

    .fa-chevron-right{
        display: none;
    }
}

body:not(.nav-small) .nav-group-items {
    display:none;
}

body:not(.nav-small) .active-group .nav-group-items {
    display:flex;
}

.nav-small .nav-size-toggle .fa-chevron-right{
    display: inline-block;
}
.nav-small .nav-size-toggle .fa-chevron-left{
    display: none;
}

.nav-small .frame-nav{
    width: 6.5rem;
}
.nav-small .frame-content{
    padding-left: 6.5rem;
}

.nav-small .nav-main .icon{
    margin-right: 0;
}

.nav-small .nav-main > .nav-item > .nav-link{
    padding-left: .4rem;
    justify-content: center;
}

.nav-small .nav-main > .nav-item > .nav-link .nav-item-text{
    display: none;
}

.nav-small .nav-main > .nav-item .nav{
    display: none;
}
.nav-small .nav-main > .nav-item:hover .nav{
    display: flex;
    position: absolute;
    z-index: 517;
    left: 100%;
    width: 20rem;
    background: #f9fafb;
    box-shadow: 0 0 6px rgba(0,0,0,.05);
    border-radius: 4px;
    padding-top: .8rem;
    padding-bottom: .8rem;

    .nav-link{
        padding-left: 1.2rem;
    }

    &.nav-flags{
        padding-left: 0;
    }
}

.nav-small .nav-main > .nav-item > .nav-link .nav-item-badge {
    padding: 0.1rem 0.6rem;
    border-radius: 2rem;
    position: absolute;
    right: -3px;
    top: 0;
    pointer-events: none;
}

.nav-item-text img{
    vertical-align: baseline;
}

.nav-flags{
    padding-left: 4.8rem;
}

.nav-item .nav.nav-flags .nav-link{
    padding: 0 0.8rem;
    margin: 0;
}

.nav-small .nav-changelog{
    display: none;
}