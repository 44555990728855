$input-height:                  $input-height-inner;

$chosen-gray-darkest:           $gray-900;
$chosen-gray-darker:            $gray-700;
$chosen-gray-dark:              #454f5b;
$chosen-gray:                   $gray-500;
$chosen-gray-light:             $gray-400;
$chosen-gray-lighter:           $gray-300;
$chosen-gray-lightest:          $gray-100;
$chosen-success:                $success;
$chosen-danger:                 $danger;

$chosen-background:             $input-bg;
$chosen-border:                 1px solid $input-border-color;
$chosen-border-color:           $input-focus-border-color;
$chosen-border-radius:          $input-border-radius;
$chosen-multi-border-radius:    $chosen-border-radius;
$chosen-box-shadow:             none;
$chosen-drop-border:            $input-focus-border-color;
$chosen-drop-box-shadow:        0 4px 5px 0 rgba(0,0,0,.075), 0 2px 5px 0 $input-focus-box-shadow;
$chosen-drop-zindex:            1060;
$chosen-focus-border:           1px solid $input-focus-border-color;
$chosen-focus-box-shadow:       rgba($chosen-border-color, 0.5);
$chosen-focus-transition:       border linear 0ms, box-shadow linear 0ms;
$chosen-placeholder-color:      $input-placeholder-color;
$chosen-height:                 $input-height;
$chosen-multi-height:           4.8rem;
$chosen-choice-max-width:       320px;    // Set to `auto` if fullwidth
$chosen-choice-truncate:        true;     // Set to `false` for no truncation

$chosen-svg-color:              $chosen-gray;
$chosen-svg-color-selected:     $chosen-gray-darker;
$chosen-svg-up:                 "M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z";
$chosen-svg-down:               "M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z";
$chosen-svg-search:             "M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z";
$chosen-svg-selected:           "M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z";
$chosen-svg-deselect:           "M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z";
$chosen-svg-close:              "M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z";
$chosen-svg-close-color:        $chosen-danger;
$chosen-svg-close-color-hover:  darken($chosen-danger, 20%);
