form.ajax-load-row{
    width: 100%;
}

.ajax-load-row .text-part-focus {
    background-color: #E4E5E7;
    line-height: 2.2;
    font-size: 1.4rem;
    padding: 2px 8px 4px;
    border-radius: 10px;
    box-decoration-break: clone;
    cursor: pointer;
}

.ajax-load-row .text-part-focus.active,
.ajax-load-row .text-part-focus.hover{
    background-color: #A4E8F2;
}

.ajax-load-row .text-part-focus.disabled,
.ajax-load-row .text-part-disabled {
    background-color: #ffffff;
    color: #6D7175;
}

.text-part-badge{
    background: #F2F2F2;
    color: #202223;
    font-size: 1.2rem;
    line-height: 1.5;
    display: inline-flex;
    align-items: center;
    padding: 0 0.5rem;
    border-radius: 2rem;
    margin-right: 4px;
}
.ajax-load-row .text-part-focus.disabled .text-part-badge {
    display:none;
}

.text-hints img{
    max-width: 100%;
}

.text-hints table{
    border: none !important;
    border-collapse: collapse;
    overflow: auto;
    display: block;
    width: 100%;
    overflow: auto;
    display: block;

    td, th{
        border: 1px solid #c4cdd5;
        padding: 4px;
    }
}

.text-part center{
    display: inline-block;
    width: calc(100% - 50px);
}


.input-test {
    display: inline;
    position: absolute;
    left: -9999px;
    font-weight: inherit;
    font-family: inherit;
    font-size: 1.5rem;
    padding: 0.5rem 1.2rem;
    line-height: 2.4rem;
    min-height: 3.6rem;
}


.phrase-edit{
    margin-bottom: 1rem;

    &:nth-last-child(1){
        margin-bottom: 0;
    }
}

.phrase-edit-textarea{
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;

    &:nth-last-child(1){
        margin-bottom: 0;
    }
}

.phrase-edit-textarea .form-group{
    flex-grow: 1;
    max-width: calc(100% - 40px);
    margin-bottom: 0;
}

.phrase-edit-textarea .highlight-area{
    border: 1px solid #c4cdd5;
    border-radius: 4px;
    flex-grow: 1;
}

.phrase-edit-textarea .highlight-area textarea{
    border: none;

    &:focus{
        border:none;
        box-shadow: none;
    }
}
.phrase-edit-textarea .ck-focused {
    border: none !important;
    box-shadow: none !important;
}
.phrase-edit-textarea .ck p{
    padding: 0 !important;
    margin: .5rem 0 !important;
}
.phrase-edit-textarea .ck{
    flex-grow: 1;
    border: none !important;
}

.phrase-edit-textarea.selected .highlight-area{
    border-color: #95C9B4;
    box-shadow: 0 0 0 1px #95C9B4;
}

.phrase-edit-textarea.skip-this-text .highlight-area{
    border-color: #c4cdd5;
    box-shadow: none;
    opacity: .5;
}

.phrase-edit-textarea.active .highlight-area,
.phrase-edit-textarea.hover .highlight-area{
    border-color: #458FFF;
    box-shadow: 0 0 0 1px #458FFF;
    opacity: 1;
}

.phrase-edit-textarea  .text-part-badge{
    margin-top: 5px;
    margin-left: 10px;
    align-self: flex-start;
}
.phrase-edit-textarea.active  .text-part-badge,
.phrase-edit-textarea.hover  .text-part-badge{
    background: #56CCF2;
    color: white;
}

.phrase-edit-textarea-actions{
    display: none;

    .btn{
        min-height: 3.2rem;
    }
}
.phrase-edit-textarea.active,
.phrase-edit-textarea:hover{
    .phrase-edit-textarea-actions{
        display: inline-block;
    }
}
.phrase-edit-textarea-actions.show{
    display: inline-block;
}

.phrase-edit-textarea.skip-this-text .text-part-badge {
    background: #e24b4b;
    color: white;
}

.product-thumbnail{
    .product-thumbnail-carousel{
        user-select: none;
        position: relative;
        width: 120px;

        &:not(.product-thumbnail-single){
            padding: 0 40px;
            width: 200px;
        }
    }

    .carousel-control-prev,
    .carousel-control-next {
        color: #5C5F62;

        &:hover, &:focus{
            color: #444749;
        }
    }
}

.product-thumbnail-item{
    padding: 1px;
    cursor: zoom-in !important;

    img{
        border: 1px solid #C9CCCF;
        border-radius: 2px;
        background: #f5f6f7;
        min-height: 50px;
    }
}

.product-thumbnail-carousel.is-loading{
    height: 1px;
    opacity: 0;
    filter: alpha(opacity=0);
    overflow: hidden;
}

.ckeditor{
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 0.6em;
    padding-right: 0.6em;

    p{
        margin-top: 0;
        margin-bottom: 0;
    }

    p + p{
        margin-top: .5rem;
    }

    h1,h2,h3,h4,h5,h6{
        margin-top: .5rem;
        margin-bottom: .5rem;
    }
}

.phrase-edit-check .text-hints{
    padding-top: .5rem;
}
