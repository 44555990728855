html{
    font-size: 62.5%;
}

body{
    font-size: 1.5rem;
    padding: 0;
}

.fa-16 {font-size: 16px;}
.fa-24 {font-size: 24px;}
.fa-32 {font-size: 32px;}

.list-action-item {text-decoration: none;color:#737577;}

.language-column {
    text-align:center;
    line-height: 16px;
    white-space: nowrap;
}
td.language-column{
    width: 1%;
}
.language-column .next-language {width:100%;text-align: right;padding-right: 3px;}
.language-column .next-language i {margin-right:4px;}
.language-column i {font-size: 10px;color:#929191;}
.language-column hr {margin: 6px 0px;}

.statistic-column div {float:left;margin-left: 10px;}
.statistic-column div span {
    font-size: 9px;
    height: 15px;
    width: 15px;
    background-color: #ffd3d3;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    margin-left: -2px;
}

.hint-replace-text {color:red;}
.progress-list {font-size: 8px;}

h1{
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}


.card-progress{
    & + &{
        margin-top: 1rem;
    }
}

.card-body > table{
    width: 100%;
}

.dashboard .card{
    margin-bottom: 2rem;
    height: calc(100% - 2rem);
}

.table.table-borderless td{
    border-top: 0;
}

.login-as-user-select .chosen-container.chosen-with-drop .chosen-single {
    color: black !important;
    padding:0px 0px 0px 8px !important;
    font-weight: normal;
}

.login-as-user-select .chosen-single {
    background: none;
    box-shadow: none !important;
    padding:0;
    border:none;
    font-weight: 400;
    font-size: 1.5rem;
    min-width: 150px;
}

.login-as-user-select .chosen-single div {
    display:none !important;
}

textarea{
    max-width: 100%;
    word-break: break-word;
}

.frame-top{
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
}

.frame-top .nav-link{
    color: $body-color;
}

.frame-top .dropdown-menu-right {
    right: 0;
    left: auto;
}

.login-as-user-select{
    line-height: 1;
}

.frame-content{
    padding-bottom: 2rem;
}

h1.display-2 img{
    vertical-align: baseline;
}

h1 .fa-angle-double-right{
    font-size: 1.5rem;
    vertical-align: middle;
}

.content-loading{
    display: none;
    margin-top: 70px;
    text-align: center;
}
.is-loading .content-loading{
    display: block;
}

.btn i:nth-child(1){
    margin-right: 1rem;
}

.flags-translation{
    display: inline-flex;
    align-items: center;

    i{
        margin-left: .5rem;
        margin-right: .5rem;
        color: #637381;
    }
}

.badge::first-letter{
    text-transform: uppercase;
}

.Polaris-DataTable__Cell--header{
    font-weight: 500;
}

.navbar-user{
    font-size: 1.4rem;
}
.avatar{
    background: #FFC96B;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
}

.flag{
    width: 18px;
    height: auto;
}

.Polaris-DataTable__Cell{
    white-space: normal;
}
.Polaris-DataTable__Cell--numeric{
    white-space: nowrap;
}

.pagination{
    margin-bottom: 0;
}

.page-link{
    min-width: 3rem;
}

.card-auth{
    width: 620px;
    max-width: calc(100% - 20px);
    padding-top: 60px;

    @media screen and (min-width: 900px){
        padding-top: 160px;
    }

    .form-group{
        margin-bottom: 2.5rem;
    }
}

.logo{
    font-size: 22px !important;
    font-weight: 400 !important;
}

.invalid-feedback{
    font-size: 1.4rem;
    line-height: 1.6rem;
}

.settings .icon{
    color: $text-muted;
    font-size: 3.6rem;
}

.settings hr{
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.settings-item:hover{
    background: #F9FAFB;
}

.btn-sq{
    padding-left: .4rem;
    padding-right: .4rem;
    text-align: center;
    width: 3.6rem;

    i:first-child{
        margin-right: 0;
    }
}

.page-header .icon-ArrowLeftMinor{
    font-size: 1.8rem;
}

select.form-control{
    color: $body-color;
}


.product-image{
    border: 1px solid #C9CCCF;
    border-radius: 2px;
}

.Polaris-Icon{
    font-size: 2rem;
}

.dropdown-item{
    cursor: pointer;
}

.dropdown-item .icon{
    font-size: 2rem;
    &:nth-child(1){
        margin-right: 1rem;
    }
    &:nth-last-child(1){
        margin-left: 1rem;
    }
}

.dropdown-item:active .icon,
.dropdown-item.active .icon,
.dropdown-item.selected .icon{
    color: #2c6ecb;
}

.dropdown-toggle::after{
    margin-left: 1rem;
}

.product-image-small{
    border: 1px solid #C9CCCF;
    border-radius: 2px;
    background: #f5f6f7;
}

.page-header .display-2{
    line-height: 3.6rem;
    margin-top: 0;
    margin-bottom: 0;
}

.page-pagination{
    background-color: #fff;
    background-clip: border-box;
    box-shadow: 0 0 0 1px rgb(69 79 91 / 5%), 0 1px 3px 0 rgb(69 79 91 / 15%);
    border-radius: 0.8rem;
    padding: 2rem;
    margin-top: 1.6rem;
}

.input-password{
    position: relative;
}

.input-password-toggle{
    position: absolute;
    z-index: 10;
    right: 0;
    top: 0;
    height: 100%;
    width: 3.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.alert li:first-letter {
    text-transform: uppercase;
}

.modal.big {
    padding: 0 !important; // override inline padding-right added from js
}
.modal.big .modal-dialog {
    width: 90%;
    max-width: none;
    margin: auto;
}
.modal.big .modal-content {
    height: 90%;
    border: 0;
    border-radius: 0;
}
.modal.big .modal-body {
    overflow-y: auto;
}

.frame-toast-manager{
    bottom: auto;
    top: 100%;
}
.frame-toast.show {
    transform: translateY(calc(-100% - 20px));
}

.custom-select{
    padding: 0.5rem 2.4rem 0.5rem 1.2rem;
    line-height: 2.4rem;
}

.input-group-append .input-group-text span[data-toggle="tooltip"]{
    display: block;
    padding: .5rem;
}

.ui-datepicker {
    z-index: 600 !important;
}


.edit-project-priority-sortable-content {
    width: 100% !important;
}

.edit-project-priority-sortable-content li {
    padding: 5px;
    margin-bottom: 2px;
}


.user-translator-index.nav-item-badge {
    line-height: 0.8rem;
    padding: 0.4rem 0.4rem;
    background-color: #aee9d1;
    margin-right: 5px;
}

.dropzone .dz-preview .dz-progress{
    display: none;
}

.dz-preview .btn{
    margin-top: 5px;
    pointer-events: auto;
}

// .translator-dictionary-row:not(.disabled):hover {
//     background-color: #A4E8F2;
// }

.to-translate-editor-icon-content {
    position: relative;
    min-width: 28px;
}

.to-translate-editor-icon {
    position: absolute;
    top:7px;
    left:10px;
}

.translator-dictionary-row.disabled {
    opacity: 0.4;
}

.Polaris-Tag.Polaris-Tag--clickable {
    line-height: 1;
}

.Polaris-IndexTable{
    overflow: visible;
}

.btn-primary:disabled {
    color: #fff;
    background-color: #b6c3bf;
    background-image: none;
    border-color: #313232;
    box-shadow: none;
}

.btn-primary:disabled:hover {
    color: #fff;
    background-color: #8c9791;
    background-image: none;
    border-color: #313232;
    box-shadow: none;
}

.Polaris-IndexTable__TableCell{
    white-space: normal !important;
}
