@font-face {
    font-family: 'icomoon';
    src:  url('/fonts/icomoon.eot?ekvlls');
    src:  url('/fonts/icomoon.eot?ekvlls#iefix') format('embedded-opentype'),
      url('/fonts/icomoon.ttf?ekvlls') format('truetype'),
      url('/fonts/icomoon.woff?ekvlls') format('woff'),
      url('/fonts/icomoon.svg?ekvlls#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: inherit;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-AbandonedCartMajor:before {
content: "\e900";
}
.icon-AccessibilityMajor:before {
content: "\e901";
}
.icon-ActivitiesMajor:before {
content: "\e902";
}
.icon-AddCodeMajor:before {
content: "\e903";
}
.icon-AddImageMajor:before {
content: "\e904";
}
.icon-AddMajor:before {
content: "\e905";
}
.icon-AddNoteMajor:before {
content: "\e906";
}
.icon-AddProductMajor:before {
content: "\e907";
}
.icon-AffiliateMajor:before {
content: "\e908";
}
.icon-AlertMinor:before {
content: "\e909";
}
.icon-AnalyticsMajor:before {
content: "\e90a";
}
.icon-AppExtensionMinor:before {
content: "\e90b";
}
.icon-AppsMajor:before {
content: "\e90c";
}
.icon-ArchiveMajor:before {
content: "\e90d";
}
.icon-ArchiveMinor:before {
content: "\e90e";
}
.icon-ArrowDownMinor:before {
content: "\e90f";
}
.icon-ArrowLeftMinor:before {
content: "\e910";
}
.icon-ArrowRightMinor:before {
content: "\e911";
}
.icon-ArrowUpMinor:before {
content: "\e912";
}
.icon-AttachmentMajor:before {
content: "\e913";
}
.icon-AutomationMajor:before {
content: "\e914";
}
.icon-BackspaceMajor:before {
content: "\e915";
}
.icon-BalanceMajor:before {
content: "\e916";
}
.icon-BankMajor:before {
content: "\e917";
}
.icon-BarcodeMajor:before {
content: "\e918";
}
.icon-BehaviorMajor:before {
content: "\e919";
}
.icon-BillingStatementDollarMajor:before {
content: "\e91a";
}
.icon-BillingStatementEuroMajor:before {
content: "\e91b";
}
.icon-BillingStatementPoundMajor:before {
content: "\e91c";
}
.icon-BillingStatementRupeeMajor:before {
content: "\e91d";
}
.icon-BillingStatementYenMajor:before {
content: "\e91e";
}
.icon-BlockquoteMajor:before {
content: "\e91f";
}
.icon-BlogMajor:before {
content: "\e920";
}
.icon-BugMajor:before {
content: "\e921";
}
.icon-ButtonCornerPillMajor:before {
content: "\e922";
}
.icon-ButtonCornerRoundedMajor:before {
content: "\e923";
}
.icon-ButtonCornerSquareMajor:before {
content: "\e924";
}
.icon-BuyButtonButtonLayoutMajor:before {
content: "\e925";
}
.icon-BuyButtonHorizontalLayoutMajor:before {
content: "\e926";
}
.icon-BuyButtonMajor:before {
content: "\e927";
}
.icon-BuyButtonVerticalLayoutMajor:before {
content: "\e928";
}
.icon-CalendarMajor:before {
content: "\e929";
}
.icon-CalendarMinor:before {
content: "\e92a";
}
.icon-CalendarTickMajor:before {
content: "\e92b";
}
.icon-CameraMajor:before {
content: "\e92c";
}
.icon-CancelSmallMinor:before {
content: "\e92d";
}
.icon-CapitalMajor:before {
content: "\e92e";
}
.icon-CapturePaymentMinor:before {
content: "\e92f";
}
.icon-CardReaderChipMajor:before {
content: "\e930";
}
.icon-CardReaderMajor:before {
content: "\e931";
}
.icon-CardReaderTapMajor:before {
content: "\e932";
}
.icon-CaretDownMinor:before {
content: "\e933";
}
.icon-CaretUpMinor:before {
content: "\e934";
}
.icon-CartDownMajor:before {
content: "\e935";
}
.icon-CartMajor:before {
content: "\e936";
}
.icon-CartUpMajor:before {
content: "\e937";
}
.icon-CashDollarMajor:before {
content: "\e938";
}
.icon-CashEuroMajor:before {
content: "\e939";
}
.icon-CashPoundMajor:before {
content: "\e93a";
}
.icon-CashRupeeMajor:before {
content: "\e93b";
}
.icon-CashYenMajor:before {
content: "\e93c";
}
.icon-CategoriesMajor:before {
content: "\e93d";
}
.icon-CircleAlertMajor:before {
content: "\e93e";
}
.icon-CircleCancelMajor:before {
content: "\e93f";
}
.icon-CircleCancelMinor:before {
content: "\e940";
}
.icon-CircleDisabledMajor:before {
content: "\e941";
}
.icon-CircleDisableMinor:before {
content: "\e942";
}
.icon-CircleDotsMajor:before {
content: "\e943";
}
.icon-CircleDownMajor:before {
content: "\e944";
}
.icon-CircleChevronDownMinor:before {
content: "\e945";
}
.icon-CircleChevronLeftMinor:before {
content: "\e946";
}
.icon-CircleChevronRightMinor:before {
content: "\e947";
}
.icon-CircleChevronUpMinor:before {
content: "\e948";
}
.icon-CircleInformationMajor:before {
content: "\e949";
}
.icon-CircleLeftMajor:before {
content: "\e94a";
}
.icon-CircleMinusMajor:before {
content: "\e94b";
}
.icon-CircleMinusMinor:before {
content: "\e94c";
}
.icon-CircleMinusOutlineMinor:before {
content: "\e94d";
}
.icon-CirclePlusMajor:before {
content: "\e94e";
}
.icon-CirclePlusMinor:before {
content: "\e94f";
}
.icon-CirclePlusOutlineMinor:before {
content: "\e950";
}
.icon-CircleRightMajor:before {
content: "\e951";
}
.icon-CircleTickMajor:before {
content: "\e952";
}
.icon-CircleTickOutlineMinor:before {
content: "\e953";
}
.icon-CircleUpMajor:before {
content: "\e954";
}
.icon-ClipboardMinor:before {
content: "\e955";
}
.icon-ClockMajor:before {
content: "\e956";
}
.icon-ClockMinor:before {
content: "\e957";
}
.icon-CodeMajor:before {
content: "\e958";
}
.icon-CollectionsMajor:before {
content: "\e959";
}
.icon-ColorNoneMinor:before {
content: "\e95a";
}
.icon-ColorsMajor:before {
content: "\e95b";
}
.icon-Column1Major:before {
content: "\e95c";
}
.icon-Columns2Major:before {
content: "\e95d";
}
.icon-Columns3Major:before {
content: "\e95e";
}
.icon-ColumnWithTextMajor:before {
content: "\e95f";
}
.icon-ComposeMajor:before {
content: "\e960";
}
.icon-ConfettiMajor:before {
content: "\e961";
}
.icon-ConnectMinor:before {
content: "\e962";
}
.icon-ConversationMinor:before {
content: "\e963";
}
.icon-CreditCardMajor:before {
content: "\e964";
}
.icon-CreditCardPercentMajor:before {
content: "\e965";
}
.icon-CreditCardSecureMajor:before {
content: "\e966";
}
.icon-CurrencyConvertMinor:before {
content: "\e967";
}
.icon-CustomerMinusMajor:before {
content: "\e968";
}
.icon-CustomerPlusMajor:before {
content: "\e969";
}
.icon-CustomersMajor:before {
content: "\e96a";
}
.icon-CustomersMinor:before {
content: "\e96b";
}
.icon-DataVisualizationMajor:before {
content: "\e96c";
}
.icon-DeleteMajor:before {
content: "\e96d";
}
.icon-DeleteMinor:before {
content: "\e96e";
}
.icon-DesktopMajor:before {
content: "\e96f";
}
.icon-DetailedPopUpMajor:before {
content: "\e970";
}
.icon-DiamondAlertMajor:before {
content: "\e971";
}
.icon-DigitalMediaReceiverMajor:before {
content: "\e972";
}
.icon-DiscountAutomaticMajor:before {
content: "\e973";
}
.icon-DiscountCodeMajor:before {
content: "\e974";
}
.icon-DiscountsMajor:before {
content: "\e975";
}
.icon-DisputeMinor:before {
content: "\e976";
}
.icon-DnsSettingsMajor:before {
content: "\e977";
}
.icon-DomainNewMajor:before {
content: "\e978";
}
.icon-DomainsMajor:before {
content: "\e979";
}
.icon-DraftOrdersMajor:before {
content: "\e97a";
}
.icon-DragDropMajor:before {
content: "\e97b";
}
.icon-DragHandleMinor:before {
content: "\e97c";
}
.icon-DropdownMinor:before {
content: "\e97d";
}
.icon-DuplicateMinor:before {
content: "\e97e";
}
.icon-EditMajor:before {
content: "\e97f";
}
.icon-EditMinor:before {
content: "\e980";
}
.icon-EmailMajor:before {
content: "\e981";
}
.icon-EmailNewsletterMajor:before {
content: "\e982";
}
.icon-EmbedMinor:before {
content: "\e983";
}
.icon-EnableSelectionMinor:before {
content: "\e984";
}
.icon-EnvelopeMajor:before {
content: "\e985";
}
.icon-ExchangeMajor:before {
content: "\e986";
}
.icon-ExistingInventoryMajor:before {
content: "\e987";
}
.icon-ExportMinor:before {
content: "\e988";
}
.icon-ExternalMinor:before {
content: "\e989";
}
.icon-ExternalSmallMinor:before {
content: "\e98a";
}
.icon-FaviconMajor:before {
content: "\e98b";
}
.icon-FavoriteMajor:before {
content: "\e98c";
}
.icon-FeaturedCollectionMajor:before {
content: "\e98d";
}
.icon-FeaturedContentMajor:before {
content: "\e98e";
}
.icon-FilterMajor:before {
content: "\e98f";
}
.icon-FirstOrderMajor:before {
content: "\e990";
}
.icon-FirstVisitMajor:before {
content: "\e991";
}
.icon-FlagMajor:before {
content: "\e992";
}
.icon-FlipCameraMajor:before {
content: "\e993";
}
.icon-FolderDownMajor:before {
content: "\e994";
}
.icon-FolderMajor:before {
content: "\e995";
}
.icon-FolderMinusMajor:before {
content: "\e996";
}
.icon-FolderPlusMajor:before {
content: "\e997";
}
.icon-FolderUpMajor:before {
content: "\e998";
}
.icon-FollowUpEmailMajor:before {
content: "\e999";
}
.icon-FoodMajor:before {
content: "\e99a";
}
.icon-FooterMajor:before {
content: "\e99b";
}
.icon-FormsMajor:before {
content: "\e99c";
}
.icon-FraudProtectMajor:before {
content: "\e99d";
}
.icon-FraudProtectMinor:before {
content: "\e99e";
}
.icon-FraudProtectPendingMajor:before {
content: "\e99f";
}
.icon-FraudProtectPendingMinor:before {
content: "\e9a0";
}
.icon-FraudProtectUnprotectedMajor:before {
content: "\e9a1";
}
.icon-FraudProtectUnprotectedMinor:before {
content: "\e9a2";
}
.icon-GamesConsoleMajor:before {
content: "\e9a3";
}
.icon-GiftCardMajor:before {
content: "\e9a4";
}
.icon-GiftCardMinor:before {
content: "\e9a5";
}
.icon-GlobeMajor:before {
content: "\e9a6";
}
.icon-GlobeMinor:before {
content: "\e9a7";
}
.icon-GrammarMajor:before {
content: "\e9a8";
}
.icon-HashtagMajor:before {
content: "\e9a9";
}
.icon-HeaderMajor:before {
content: "\e9aa";
}
.icon-HeartMajor:before {
content: "\e9ab";
}
.icon-HideKeyboardMajor:before {
content: "\e9ac";
}
.icon-HideMinor:before {
content: "\e9ad";
}
.icon-HintMajor:before {
content: "\e9ae";
}
.icon-HomeMajor:before {
content: "\e9af";
}
.icon-HorizontalDotsMinor:before {
content: "\e9b0";
}
.icon-ChannelsMajor:before {
content: "\e9b1";
}
.icon-ChatMajor:before {
content: "\e9b2";
}
.icon-ChecklistAlternateMajor:before {
content: "\e9b3";
}
.icon-ChecklistMajor:before {
content: "\e9b4";
}
.icon-CheckoutMajor:before {
content: "\e9b5";
}
.icon-ChevronDownMinor:before {
content: "\e9b6";
}
.icon-ChevronLeftMinor:before {
content: "\e9b7";
}
.icon-ChevronRightMinor:before {
content: "\e9b8";
}
.icon-ChevronUpMinor:before {
content: "\e9b9";
}
.icon-IconsMajor:before {
content: "\e9ba";
}
.icon-IllustrationMajor:before {
content: "\e9bb";
}
.icon-ImageAltMajor:before {
content: "\e9bc";
}
.icon-ImageAltMinor:before {
content: "\e9bd";
}
.icon-ImageMajor:before {
content: "\e9be";
}
.icon-ImagesMajor:before {
content: "\e9bf";
}
.icon-ImageWithTextMajor:before {
content: "\e9c0";
}
.icon-ImageWithTextOverlayMajor:before {
content: "\e9c1";
}
.icon-ImportMinor:before {
content: "\e9c2";
}
.icon-ImportStoreMajor:before {
content: "\e9c3";
}
.icon-IncomingMajor:before {
content: "\e9c4";
}
.icon-InfoMinor:before {
content: "\e9c5";
}
.icon-InstallMinor:before {
content: "\e9c6";
}
.icon-InventoryMajor:before {
content: "\e9c7";
}
.icon-InviteMinor:before {
content: "\e9c8";
}
.icon-IqMajor:before {
content: "\e9c9";
}
.icon-JobsMajor:before {
content: "\e9ca";
}
.icon-KeyMajor:before {
content: "\e9cb";
}
.icon-LabelPrinterMajor:before {
content: "\e9cc";
}
.icon-LandingPageMajor:before {
content: "\e9cd";
}
.icon-LanguageMinor:before {
content: "\e9ce";
}
.icon-LegalMajor:before {
content: "\e9cf";
}
.icon-LinkMinor:before {
content: "\e9d0";
}
.icon-ListMajor:before {
content: "\e9d1";
}
.icon-LiveViewMajor:before {
content: "\e9d2";
}
.icon-LocationMajor:before {
content: "\e9d3";
}
.icon-LocationsMinor:before {
content: "\e9d4";
}
.icon-LockMajor:before {
content: "\e9d5";
}
.icon-LockMinor:before {
content: "\e9d6";
}
.icon-LogoBlockMajor:before {
content: "\e9d7";
}
.icon-LogOutMinor:before {
content: "\e9d8";
}
.icon-ManagedStoreMajor:before {
content: "\e9d9";
}
.icon-MarketingMajor:before {
content: "\e9da";
}
.icon-MarkFulfilledMinor:before {
content: "\e9db";
}
.icon-MarkPaidMinor:before {
content: "\e9dc";
}
.icon-MaximizeMajor:before {
content: "\e9dd";
}
.icon-MaximizeMinor:before {
content: "\e9de";
}
.icon-MentionMajor:before {
content: "\e9df";
}
.icon-MicrophoneMajor:before {
content: "\e9e0";
}
.icon-MinimizeMajor:before {
content: "\e9e1";
}
.icon-MinimizeMinor:before {
content: "\e9e2";
}
.icon-MinusMinor:before {
content: "\e9e3";
}
.icon-MobileAcceptMajor:before {
content: "\e9e4";
}
.icon-MobileBackArrowMajor:before {
content: "\e9e5";
}
.icon-MobileCancelMajor:before {
content: "\e9e6";
}
.icon-MobileHamburgerMajor:before {
content: "\e9e7";
}
.icon-MobileHorizontalDotsMajor:before {
content: "\e9e8";
}
.icon-MobileChevronMajor:before {
content: "\e9e9";
}
.icon-MobileMajor:before {
content: "\e9ea";
}
.icon-MobilePlusMajor:before {
content: "\e9eb";
}
.icon-MobileVerticalDotsMajor:before {
content: "\e9ec";
}
.icon-MonerisMajor:before {
content: "\e9ed";
}
.icon-NatureMajor:before {
content: "\e9ee";
}
.icon-NavigationMajor:before {
content: "\e9ef";
}
.icon-NoteMajor:before {
content: "\e9f0";
}
.icon-NoteMinor:before {
content: "\e9f1";
}
.icon-NotificationMajor:before {
content: "\e9f2";
}
.icon-OnlineStoreMajor:before {
content: "\e9f3";
}
.icon-OrdersMajor:before {
content: "\e9f4";
}
.icon-OrderStatusMinor:before {
content: "\e9f5";
}
.icon-OutgoingMajor:before {
content: "\e9f6";
}
.icon-PackageMajor:before {
content: "\e9f7";
}
.icon-PageDownMajor:before {
content: "\e9f8";
}
.icon-PageMajor:before {
content: "\e9f9";
}
.icon-PageMinusMajor:before {
content: "\e9fa";
}
.icon-PagePlusMajor:before {
content: "\e9fb";
}
.icon-PageUpMajor:before {
content: "\e9fc";
}
.icon-PaginationEndMinor:before {
content: "\e9fd";
}
.icon-PaginationStartMinor:before {
content: "\e9fe";
}
.icon-PaintBrushMajor:before {
content: "\e9ff";
}
.icon-PauseCircleMajor:before {
content: "\ea00";
}
.icon-PauseMajor:before {
content: "\ea01";
}
.icon-PauseMinor:before {
content: "\ea02";
}
.icon-PaymentsMajor:before {
content: "\ea03";
}
.icon-PhoneInMajor:before {
content: "\ea04";
}
.icon-PhoneMajor:before {
content: "\ea05";
}
.icon-PhoneOutMajor:before {
content: "\ea06";
}
.icon-PinMajor:before {
content: "\ea07";
}
.icon-PinMinor:before {
content: "\ea08";
}
.icon-PlayCircleMajor:before {
content: "\ea09";
}
.icon-PlayMajor:before {
content: "\ea0a";
}
.icon-PlayMinor:before {
content: "\ea0b";
}
.icon-PlusMinor:before {
content: "\ea0c";
}
.icon-PointOfSaleMajor:before {
content: "\ea0d";
}
.icon-PopularMajor:before {
content: "\ea0e";
}
.icon-PriceLookupMinor:before {
content: "\ea0f";
}
.icon-PrintMajor:before {
content: "\ea10";
}
.icon-PrintMinor:before {
content: "\ea11";
}
.icon-ProductReturnsMinor:before {
content: "\ea12";
}
.icon-ProductsMajor:before {
content: "\ea13";
}
.icon-ProfileMajor:before {
content: "\ea14";
}
.icon-ProfileMinor:before {
content: "\ea15";
}
.icon-PromoteMinor:before {
content: "\ea16";
}
.icon-QuestionMarkMajor:before {
content: "\ea17";
}
.icon-QuestionMarkMinor:before {
content: "\ea18";
}
.icon-QuickSaleMajor:before {
content: "\ea19";
}
.icon-ReadTimeMinor:before {
content: "\ea1a";
}
.icon-ReceiptMajor:before {
content: "\ea1b";
}
.icon-RecentSearchesMajor:before {
content: "\ea1c";
}
.icon-RedoMajor:before {
content: "\ea1d";
}
.icon-ReferralCodeMajor:before {
content: "\ea1e";
}
.icon-ReferralMajor:before {
content: "\ea1f";
}
.icon-RefreshMajor:before {
content: "\ea20";
}
.icon-RefreshMinor:before {
content: "\ea21";
}
.icon-RefundMajor:before {
content: "\ea22";
}
.icon-RefundMinor:before {
content: "\ea23";
}
.icon-RemoveProductMajor:before {
content: "\ea24";
}
.icon-RepeatOrderMajor:before {
content: "\ea25";
}
.icon-ReplaceMajor:before {
content: "\ea26";
}
.icon-ReplayMinor:before {
content: "\ea27";
}
.icon-ReportMinor:before {
content: "\ea28";
}
.icon-ReportsMajor:before {
content: "\ea29";
}
.icon-ResetMinor:before {
content: "\ea2a";
}
.icon-ResourcesMajor:before {
content: "\ea2b";
}
.icon-ReturnMinor:before {
content: "\ea2c";
}
.icon-RiskMajor:before {
content: "\ea2d";
}
.icon-RiskMinor:before {
content: "\ea2e";
}
.icon-SandboxMajor:before {
content: "\ea2f";
}
.icon-SaveMinor:before {
content: "\ea30";
}
.icon-SearchMajor:before {
content: "\ea31";
}
.icon-SearchMinor:before {
content: "\ea32";
}
.icon-SectionMajor:before {
content: "\ea33";
}
.icon-SecureMajor:before {
content: "\ea34";
}
.icon-SelectMinor:before {
content: "\ea35";
}
.icon-SendMajor:before {
content: "\ea36";
}
.icon-SettingsMajor:before {
content: "\ea37";
}
.icon-SettingsMinor:before {
content: "\ea38";
}
.icon-ShareIosMinor:before {
content: "\ea39";
}
.icon-ShareMinor:before {
content: "\ea3a";
}
.icon-ShipmentMajor:before {
content: "\ea3b";
}
.icon-ShopcodesMajor:before {
content: "\ea3c";
}
.icon-SidebarLeftMajor:before {
content: "\ea3d";
}
.icon-SidebarRightMajor:before {
content: "\ea3e";
}
.icon-SlideshowMajor:before {
content: "\ea3f";
}
.icon-SmileyHappyMajor:before {
content: "\ea40";
}
.icon-SmileyJoyMajor:before {
content: "\ea41";
}
.icon-SmileyNeutralMajor:before {
content: "\ea42";
}
.icon-SmileySadMajor:before {
content: "\ea43";
}
.icon-SocialAdMajor:before {
content: "\ea44";
}
.icon-SocialPostMajor:before {
content: "\ea45";
}
.icon-SoftPackMajor:before {
content: "\ea46";
}
.icon-SortAscendingMajor:before {
content: "\ea47";
}
.icon-SortDescendingMajor:before {
content: "\ea48";
}
.icon-SortMinor:before {
content: "\ea49";
}
.icon-SoundMajor:before {
content: "\ea4a";
}
.icon-StarFilledMinor:before {
content: "\ea4b";
}
.icon-StarOutlineMinor:before {
content: "\ea4c";
}
.icon-StoreMajor:before {
content: "\ea4d";
}
.icon-StoreMinor:before {
content: "\ea4e";
}
.icon-StoreStatusMajor:before {
content: "\ea4f";
}
.icon-TabletMajor:before {
content: "\ea50";
}
.icon-TapChipMajor:before {
content: "\ea51";
}
.icon-TaxMajor:before {
content: "\ea52";
}
.icon-TeamMajor:before {
content: "\ea53";
}
.icon-TemplateMajor:before {
content: "\ea54";
}
.icon-TextAlignmentCenterMajor:before {
content: "\ea55";
}
.icon-TextAlignmentLeftMajor:before {
content: "\ea56";
}
.icon-TextAlignmentRightMajor:before {
content: "\ea57";
}
.icon-TextBlockMajor:before {
content: "\ea58";
}
.icon-TextMajor:before {
content: "\ea59";
}
.icon-ThemeEditMajor:before {
content: "\ea5a";
}
.icon-ThemesMajor:before {
content: "\ea5b";
}
.icon-ThemeStoreMajor:before {
content: "\ea5c";
}
.icon-ThumbsDownMajor:before {
content: "\ea5d";
}
.icon-ThumbsDownMinor:before {
content: "\ea5e";
}
.icon-ThumbsUpMajor:before {
content: "\ea5f";
}
.icon-ThumbsUpMinor:before {
content: "\ea60";
}
.icon-TickMinor:before {
content: "\ea61";
}
.icon-TickSmallMinor:before {
content: "\ea62";
}
.icon-TimelineAttachmentMajor:before {
content: "\ea63";
}
.icon-TipsMajor:before {
content: "\ea64";
}
.icon-ToolsMajor:before {
content: "\ea65";
}
.icon-TransactionFeeDollarMajor:before {
content: "\ea66";
}
.icon-TransactionFeeEuroMajor:before {
content: "\ea67";
}
.icon-TransactionFeePoundMajor:before {
content: "\ea68";
}
.icon-TransactionFeeRupeeMajor:before {
content: "\ea69";
}
.icon-TransactionFeeYenMajor:before {
content: "\ea6a";
}
.icon-TransactionMajor:before {
content: "\ea6b";
}
.icon-TransferInMajor:before {
content: "\ea6c";
}
.icon-TransferMajor:before {
content: "\ea6d";
}
.icon-TransferOutMajor:before {
content: "\ea6e";
}
.icon-TransferWithinShopifyMajor:before {
content: "\ea6f";
}
.icon-TransportMajor:before {
content: "\ea70";
}
.icon-TroubleshootMajor:before {
content: "\ea71";
}
.icon-TypeMajor:before {
content: "\ea72";
}
.icon-UndoMajor:before {
content: "\ea73";
}
.icon-UnfulfilledMajor:before {
content: "\ea74";
}
.icon-UnknownDeviceMajor:before {
content: "\ea75";
}
.icon-UpdateInventoryMajor:before {
content: "\ea76";
}
.icon-UploadMajor:before {
content: "\ea77";
}
.icon-VariantMajor:before {
content: "\ea78";
}
.icon-ViewMajor:before {
content: "\ea79";
}
.icon-ViewMinor:before {
content: "\ea7a";
}
.icon-ViewportNarrowMajor:before {
content: "\ea7b";
}
.icon-ViewportWideMajor:before {
content: "\ea7c";
}
.icon-VocabularyMajor:before {
content: "\ea7d";
}
.icon-WandMajor:before {
content: "\ea7e";
}
.icon-WearableMajor:before {
content: "\ea7f";
}
.icon-WholesaleMajor:before {
content: "\ea80";
}
.icon-WifiMajor:before {
content: "\ea81";
}
