// Fonts
@import '~jquery-ui/themes/base/all.css';
@import "@fortawesome/fontawesome-free/css/all";

// Variables
@import 'base/variables';

// Bootstrap
@import '~bootstrap-polaris/scss/main';
@import '~bootstrap-polaris/scss/toast';

@import "chosen/svg-uri";
@import 'chosen/component-variables';
@import 'chosen/component-chosen';

@import 'base/polaris-icons';
@import 'base/main';
@import 'translator/editor';
@import 'translator/carousel';
@import 'translator/nav';


/** skrytí buttonu který generuje CKEditor, my ho zatím nechceme **/
.ck-block-toolbar-button {display:none !important;}

@import '~lightslider/dist/css/lightslider.css';
@import '~simplelightbox/dist/simple-lightbox.css';
@import '~dropzone/dist/dropzone.css';

#my-dropzone {
    text-align: center;
}
.dropzone {
    border: 3px dashed silver;
    border-radius: 10px;
    i {
        font-size: 25px;
        color: #5C5F62;
        pointer-events: none;
    }

    .btn{
        cursor: pointer;
        pointer-events: none;
    }

    &:hover{
        background: #f6f6f7;
    }
}

i + .action-text{
    margin-left: 1rem;
    flex-grow: 1;
    display: block;
}
